import Sortable from 'sortablejs';

function toggleDialog(dialog: HTMLDialogElement | null) {
  if (dialog?.open) {
    dialog.close();
  } else {
    dialog?.showModal();
  }
}

htmx.onLoad(function (content) {
    const sortables = content.querySelectorAll("[data-sortable]");
    for (var i = 0; i < sortables.length; i++) {
      const sortable = sortables[i];
      const sortableInstance = new Sortable(sortable, {
        animation: 150,
          ghostClass: 'bg-gray-3',
          dragClass: 'rotate-6',
          chosenClass: 'rotate-6',
      });

      // Re-enable sorting on the `htmx:afterSwap` event
      sortable.addEventListener("htmx:afterSwap", function() {
        sortableInstance.option("disabled", false);
      });
    }

  content
    .querySelectorAll<HTMLElement>("[data-toggle-dialog]")
    .forEach((element) =>
      element?.addEventListener("click", (event) => {
        event.preventDefault();
        const dialogId = element.dataset.toggleDialog;
        const dialog = document.querySelector<HTMLDialogElement>(
          `dialog#${dialogId}`,
        );
        toggleDialog(dialog);
      }),
    );

});
